import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { CrmOption } from 'common-module/common';
import { crmFormRequiredValidator } from 'common-module/form';
import { CrmFormConfigOptions } from 'common-module/form/form.provider';
import { CrmFormFragment } from 'common-module/form/fragments';
import {
  CrmFormTextareaComponent,
  crmGetFormSelect,
} from 'common-module/form/inputs';
import { CrmTranslateService } from 'common-module/translate';
import { crmGetUserName } from 'common-module/user';
import { DateTime } from 'luxon';
import {
  catchError,
  EMPTY,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

import { getAdmissionLabel } from '~/api/patients/admissions/get-admission-label';
import { PatientsAdmissionsApiService } from '~/api/patients/admissions/patients-admissions-api.service';
import { UserService } from '~/api/user/user.service';
import { ServiceType } from '~/shared/modal/schedule-visit/service-type';
import { PatientType } from '~/shared/modal/schedule-visit/patient-type';
import { CommunityType } from '~/shared/modal/schedule-visit/map-community-to-option';

import { getFormDate } from '../../components/date/get-form-date';
import {
  CustomForm,
  CustomFormProvider,
} from '../../form/custom-form.provider';
import { EventBusService } from '../../services/event-bus.service';
import { InterfaceToGroup } from '../../types/interface-to-group';
import { isPastDate } from '../../utils/date/is-past-date';

type Data = {
  community: CommunityType;
  patientType: PatientType;
  serviceType: ServiceType;
  serviceType2: ServiceType;
  serviceType3: ServiceType;
  start: DateTime;
  duration: number;
  admission: string;
  description: string;
  providers: string[];
};

type InputData = {
  communities: CommunityType[];
  user: string;
  patient: string;
  title?: string;
  originalEvent?: string;
  disableAdmissionBusChange?: true;
} & Partial<Data>;

@Injectable()
export class ScheduleVisitFormProvider extends CustomFormProvider<
  InputData,
  Data
> {
  private patientTypes = signal<CrmOption<PatientType>[]>([]);
  private patientTypes$ = toObservable(this.patientTypes);

  private serviceTypes = signal<CrmOption<ServiceType>[]>([]);
  private serviceTypes$ = toObservable(this.serviceTypes);

  private durations = signal<CrmOption<number>[]>([]);
  private durations$ = toObservable(this.durations);

  private serviceType2 = { name: '' };
  private serviceTypes2 = signal<CrmOption<ServiceType>[]>([]);
  private serviceTypes2$ = toObservable(this.serviceTypes2);

  private serviceTypes3 = signal<CrmOption<ServiceType>[]>([]);
  private serviceTypes3$ = toObservable(this.serviceTypes3);

  private admissions = signal<CrmOption[]>([]);
  private admissions$ = toObservable(this.admissions);
  private defaultAdmission?: string;

  private translate = inject(CrmTranslateService);
  private admission = inject(PatientsAdmissionsApiService);
  private user = inject(UserService);
  private eventBus = inject(EventBusService);

  override formInitialized(form: CustomForm<Data>) {
    super.formInitialized(form);

    const {
      admission: admissionControl,
      community,
      patientType,
      serviceType,
      serviceType2,
      serviceType3,
      duration,
    } = form.form.controls;

    community.valueChanges
      .pipe(
        startWith(community.value),
        filter(() => !this.isLoading),
        takeUntil(this.destroy$),
      )
      .subscribe((value) => {
        duration.reset();

        if (!value) {
          this.patientTypes.set([]);
          this.serviceTypes.set([]);
          this.serviceTypes2.set([]);
          this.serviceTypes3.set([]);
          this.durations.set([]);
          return;
        }

        if (this.data.patientType) {
          const communityPatientType = value.patientTypes?.find(
            ({ type }) => type.name === this.data.patientType?.type?.name,
          );

          if (communityPatientType) {
            patientType.setValue(communityPatientType);
          }

          delete this.data.patientType;
        } else {
          patientType.reset({});
        }

        if (this.data.serviceType) {
          const communityServiceType = value.serviceTypes?.find(
            ({ type }) => type.name === this.data.serviceType?.type?.name,
          );

          if (communityServiceType) {
            serviceType.setValue({
              id: communityServiceType.id,
              type: {
                ...communityServiceType.type,
                children: communityServiceType.type.children ?? [],
              },
            });
          }

          delete this.data.serviceType;
        } else {
          serviceType.reset({});
        }

        if (this.data.serviceType2) {
          const serviceTypeValue = serviceType.value;

          if (serviceTypeValue) {
            const serviceType2Child =
              serviceTypeValue?.type?.children?.findIndex(
                ({ name }) => name === this.data.serviceType2?.type?.name,
              );

            if (serviceType2Child != null) {
              const child =
                serviceTypeValue.type?.children?.[serviceType2Child];

              if (child) {
                this.serviceTypes2.set(
                  (serviceTypeValue.type?.children ?? []).map((st, index) =>
                    this.mapServiceTypeToOptionValue({
                      id: String(index),
                      type: st,
                    }),
                  ),
                );
                serviceType2.setValue({
                  id: String(serviceType2Child),
                  type: { ...child, children: child.children ?? [] },
                });
              }
            }
          }

          delete this.data.serviceType2;
        } else {
          serviceType2.reset({});
        }

        if (this.data.serviceType3) {
          const serviceType2Value = serviceType2.value;

          if (serviceType2Value) {
            const serviceType3Child =
              serviceType2Value?.type?.children?.findIndex(
                ({ name }) => name === this.data.serviceType3?.type?.name,
              );

            if (serviceType3Child != null) {
              const child =
                serviceType2Value.type?.children?.[serviceType3Child];

              if (child) {
                this.serviceTypes3.set(
                  (serviceType2Value.type?.children ?? []).map((st, index) =>
                    this.mapServiceTypeToOptionValue({
                      id: String(index),
                      type: st,
                    }),
                  ),
                );
                serviceType3.setValue({
                  id: String(serviceType3Child),
                  type: { ...child, children: child.children ?? [] },
                });
              }
            }
          }

          delete this.data.serviceType3;
        } else {
          serviceType3.reset({});
        }

        const patientTypes = value.patientTypes!.map((type) =>
          this.mapPatientTypeToOptionValue(type),
        );

        const serviceTypes = value.serviceTypes!.map((type) =>
          this.mapServiceTypeToOptionValue(type),
        );

        const durations = value.durations!.map((duration) =>
          this.mapDurationToOptionValue(duration),
        );

        this.patientTypes.set(patientTypes);
        this.serviceTypes.set(serviceTypes);
        this.durations.set(durations);
      });

    setTimeout(() => {
      this.initChildrenServiceType({
        parent: serviceType,
        child: serviceType2,
        options: this.serviceTypes2,
      })
        .pipe(takeUntil(this.destroy$))
        .subscribe();

      this.initChildrenServiceType({
        parent: serviceType2,
        child: serviceType3,
        options: this.serviceTypes3,
        parentName: this.serviceType2,
      })
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    });

    admissionControl.valueChanges
      .pipe(
        startWith(admissionControl.value),
        tap((admission) => {
          if (admission) {
            this.eventBus.emit({
              id: 'SCHEDULE_TELEHEALTH_VISIT:ADMISSION',
              data: admission,
            });
          }
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    if (!this.data.disableAdmissionBusChange) {
      this.eventBus
        .on<string | undefined>('FORM:ADMISSION', { remember: true })
        .pipe(
          tap((admission) => {
            if (admission) {
              this.loadAdmissionIfMissing(admission);
            }

            this.defaultAdmission = admission;
            admissionControl.setValue(admission);
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    } else {
      this.defaultAdmission = this.data.admission;
    }
  }

  protected override getFormFragments({
    data,
  }: CrmFormConfigOptions<InputData>): Observable<CrmFormFragment[]> {
    return forkJoin({
      admissions: this.admission
        .listAllWithin60Days({ patient: data.patient })
        .pipe(
          switchMap((admissions) => {
            if (!data.admission) {
              return of(admissions);
            }

            const admissionsSet = new Set(admissions.map(({ _id }) => _id));

            if (admissionsSet.has(data.admission)) {
              return of(admissions);
            }

            return this.admission.get(data.admission).pipe(
              map((admission) => [admission, ...admissions]),
              catchError(() => of(admissions)),
            );
          }),
        ),
      providers: this.user.listAllNavigators(),
    }).pipe(
      map(({ admissions, providers }) => [
        crmGetFormSelect({
          name: 'community',
          config: {
            label: 'scheduleVisit.selectMedicalCommunity',
            validators: [crmFormRequiredValidator()],
            defaultValue:
              data.communities.length === 1 ? data.communities[0] : undefined,
            disabled: data.communities.length === 1,
          },
          options: {
            placeholder: 'scheduleVisit.chooseCommunity',
            options: data.communities.map((community) => ({
              value: community,
              label: community.name,
            })),
          },
        }),
        crmGetFormSelect({
          name: 'patientType',
          config: {
            label: 'scheduleVisit.patientType',
            validators: [crmFormRequiredValidator()],
            disabled: this.patientTypes$.pipe(
              map((types) => types.length === 0),
            ),
          },
          options: { options: this.patientTypes$ },
        }),
        crmGetFormSelect({
          name: 'serviceType',
          config: {
            label: 'scheduleVisit.visitType',
            validators: [crmFormRequiredValidator()],
            disabled: this.serviceTypes$.pipe(
              map((types) => types.length === 0),
            ),
          },
          options: { options: this.serviceTypes$ },
        }),
        crmGetFormSelect({
          name: 'serviceType2',
          config: {
            label: {
              message: 'scheduleVisit.visitTypeSub',
              context: { name: this.translate.get('scheduleVisit.visitType') },
            },
            validators: [crmFormRequiredValidator()],
          },
          options: { options: this.serviceTypes2$ },
          visible$: () =>
            this.serviceTypes2$.pipe(map((options) => !!options.length)),
        }),
        crmGetFormSelect({
          name: 'serviceType3',
          config: {
            label: {
              message: 'scheduleVisit.visitTypeSub',
              context: this.serviceType2,
            },
            validators: [crmFormRequiredValidator()],
          },
          options: { options: this.serviceTypes3$ },
          visible$: () =>
            this.serviceTypes3$.pipe(map((options) => !!options.length)),
        }),
        crmGetFormSelect({
          name: 'admission',
          config: {
            label: 'scheduleVisit.admission',
            validators: [crmFormRequiredValidator()],
          },
          options: {
            options: (() => {
              this.admissions.set(
                admissions.map((model) => ({
                  label: getAdmissionLabel(model),
                  value: model._id,
                })),
              );

              return this.admissions$;
            })(),
          },
          visible$: (form: FormGroup<InterfaceToGroup<Data>>) =>
            form.controls.serviceType2.valueChanges.pipe(
              startWith(form.controls.serviceType2.value),
              map(
                (value) =>
                  value?.type?.name === 'Hospital Discharge/Transition of Care',
              ),
              tap((visible) => {
                form.controls.admission.setValue(
                  visible
                    ? (form.controls.admission.value ?? this.defaultAdmission)
                    : null,
                );
              }),
            ),
        }),
        getFormDate({
          name: 'start',
          config: {
            label: 'scheduleVisit.start',
            validators: [
              crmFormRequiredValidator(),
              {
                name: 'past',
                error: 'scheduleVisit.error.pastStart',
                validator: (control) => {
                  const value = control.value as DateTime | undefined;

                  if (!value) {
                    return null;
                  }

                  return value < DateTime.now() ? { past: true } : null;
                },
              },
            ],
          },
          layout: { span: 16 },
          options: {
            format: 'MMM dd yyyy hh:mm a',
            disabledDate: isPastDate,
            showTime: {
              nzFormat: 'hh:mm a',
              nzUse12Hours: true,
              nzMinuteStep: 15,
            },
          },
        }),
        crmGetFormSelect({
          name: 'duration',
          config: {
            label: 'scheduleVisit.duration',
            validators: [crmFormRequiredValidator()],
            disabled: this.durations$.pipe(
              map((durations) => durations.length === 0),
            ),
          },
          options: { options: this.durations$ },
          layout: { span: 8 },
        }),
        crmGetFormSelect({
          name: 'providers',
          config: {
            label: 'scheduleVisit.invitations',
            validators: [crmFormRequiredValidator()],
          },
          options: {
            mode: 'multiple',
            options: providers.map((user) => ({
              value: user._id,
              label: crmGetUserName(user),
            })),
          },
        }),
        {
          type: 'data-input',
          name: 'description',
          content: CrmFormTextareaComponent,
          contentContext: {
            config: { label: 'scheduleVisit.description' },
          },
        },
      ]),
    );
  }

  private initChildrenServiceType({
    parent,
    child,
    options,
    parentName,
  }: {
    parent: FormGroup<InterfaceToGroup<ServiceType>>;
    child: FormGroup<InterfaceToGroup<ServiceType>>;
    options: WritableSignal<CrmOption<ServiceType>[]>;
    parentName?: { name: string };
  }) {
    return parent.valueChanges.pipe(
      filter(() => !this.isLoading),
      tap((value) => {
        const subTypes = (value?.type?.children ?? []).map((child, index) => ({
          label: child.name,
          value: { id: String(index), type: child },
        }));

        options.set(subTypes);

        if (parentName) {
          parentName.name = value?.type?.name ?? '';
        }

        child.reset();
      }),
    );
  }

  private loadAdmissionIfMissing(id: string) {
    if (this.admissions().some(({ value }) => id === value)) {
      return;
    }

    this.admission
      .get(id)
      .pipe(catchError(() => EMPTY))
      .subscribe((admission) => {
        this.admissions.update((state) => [
          { label: getAdmissionLabel(admission), value: admission._id },
          ...state,
        ]);
      });
  }

  private mapPatientTypeToOptionValue(
    type: PatientType,
  ): CrmOption<PatientType> {
    return { value: type, label: type.type.name };
  }

  private mapServiceTypeToOptionValue(
    type: ServiceType,
  ): CrmOption<ServiceType> {
    return { value: type, label: type.type.name };
  }

  private mapDurationToOptionValue(duration: number): CrmOption<number> {
    return {
      value: duration,
      label: this.translate.get({
        message: 'scheduleVisit.minutes',
        context: { value: duration },
      }),
    };
  }
}
