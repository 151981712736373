import { MembershipSubjectRole, UserMembership } from './membership';

/**
 * Function which checks if database user membership model is user membership with given subjectRole and organization
 *
 * @param membership
 * @param organization
 * @param subjectRole
 */
export const isUserMemberMembership = ({
  membership,
  organization,
  subjectRole,
}: {
  membership: UserMembership;
  organization: string;
  subjectRole: MembershipSubjectRole;
}): boolean => {
  return (
    membership.type === 'user' &&
    membership.role === 'member' &&
    membership.subjectRole === subjectRole &&
    membership.organization?._id === organization
  );
};

/**
 * Function which finds user member membership in user membership for given subjectRole and organization
 *
 * @param memberships
 * @param organization
 * @param subjectRole
 */
export const findUserMemberInMemberships = ({
  memberships,
  organization,
  subjectRole,
}: {
  memberships: UserMembership[];
  organization: string;
  subjectRole: MembershipSubjectRole;
}) => {
  return memberships.find((membership) =>
    isUserMemberMembership({ membership, organization, subjectRole }),
  );
};
