import { inject } from '@angular/core';
import { CrmMessageService } from 'common-module/message';
import { crmCreateModalFormFn$ } from 'common-module/modal';
import { ModalOptions } from 'ng-zorro-antd/modal';
import { EMPTY, forkJoin, map, of, switchMap } from 'rxjs';
import { compact, intersection } from 'lodash-es';

import { OrganizationService } from '~/api/organization/organization.service';
import { UserService } from '~/api/user/user.service';
import { mapCommunityToOptionValue } from '~/shared/modal/schedule-visit/map-community-to-option';
import { UserModel } from '~/api/user/model/user.model';
import { findUserMemberInMemberships } from '~/api/user/membership/user-membership';

import { ScheduleVisitFormProvider } from './schedule-visit-form.provider';
import { ScheduleVisitProvider } from './schedule-visit.provider';

/**
 * Factory function to get open schedule visit function
 */
export const openScheduleVisitFn = () => {
  const modal = crmCreateModalFormFn$();
  const organization = inject(OrganizationService);
  const user = inject(UserService);
  const message = inject(CrmMessageService);

  return (
    data: Omit<ScheduleVisitProvider['data'], 'communities' | 'user'> & {
      selectDefaultOrFirstCommunity?: true;
      user: UserModel;
    },
    settings?: ModalOptions,
  ) =>
    forkJoin({
      currentOrganization: organization.organization$,
      author: user.user$,
    }).pipe(
      switchMap(({ currentOrganization, author }) => {
        if (data.community) {
          return of({
            organization: currentOrganization,
            communities: [data.community],
          });
        }

        const authorCommunities = new Set(
          (author.membership ?? [])
            .filter(
              ({ type, organization }) =>
                type === 'community' &&
                currentOrganization === organization?._id,
            )
            .map(({ ref }) => ref),
        );

        const userCommunities = new Set(
          (data.user.membership ?? [])
            .filter(
              ({ type, organization }) =>
                type === 'community' &&
                currentOrganization === organization?._id,
            )
            .map(({ ref }) => ref),
        );

        const communitiesIntersection = intersection(
          [...authorCommunities],
          [...userCommunities],
        );

        return organization.listCommunitiesSafe(communitiesIntersection).pipe(
          map((communities) => ({
            organization: currentOrganization,
            communities: communities
              .filter(
                ({ settings, _id }) =>
                  userCommunities.has(_id) &&
                  settings &&
                  (!settings.viewMembers || settings.telehealthVisit) &&
                  settings.patientTypes?.length &&
                  settings.serviceTypes?.length &&
                  settings.telehealthVisitDurations?.length,
              )
              .map((community) => mapCommunityToOptionValue(community)),
          })),
        );
      }),
      switchMap(({ organization, communities }) => {
        if (!communities.length) {
          message.error('scheduleVisit.missingCommunity');
          return EMPTY;
        }

        const { _id: user, membership } = data.user;

        return modal({
          title: data.title ?? 'scheduleVisit.title',
          formProvider: ScheduleVisitFormProvider,
          modalProvider: ScheduleVisitProvider,
          data: {
            ...data,
            providers:
              data.providers ??
              compact([
                findUserMemberInMemberships({
                  memberships: membership ?? [],
                  organization,
                  subjectRole: 'navigator',
                })?.ref,
              ]),
            user,
            communities,
            community: data.selectDefaultOrFirstCommunity
              ? (communities.find((c) => c.isDefault) ?? communities[0])
              : undefined,
          },
          settings,
        });
      }),
    );
};
